<template>
  <div class="pageContol">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">资源中心</a>
          <i>></i>
          <a href="javascript:;" class="cur-a">资质类型维护</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl flexdc" style="align-items:flex-start">
          <div class="searchbox" style="margin-bottom:15px">
            <div title="资质类型" class="searchboxItem ci-full">
              <span class="itemLabel">资质类型:</span>
              <el-input v-model="name" type="text" size="small" placeholder="请输入资质类型" clearable />
            </div>
            <el-button class="bgc-bv" style="margin:0 10px" round @click="getData()">查询</el-button>
            <el-button class="bgc-bv" style="margin:0 10px" round @click="handlAddorEdit()">新增</el-button>
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table ref="multipleTable" :data="tableData" height="100%" size="small" tooltip-effect="dark" style="width: 100%" :header-cell-style="tableHeader" stripe>
              <el-table-column label="序号" align="center" type="index" :index="indexMethod" min-width="100" />
              <el-table-column label="资质类型" align="left" prop="name" show-overflow-tooltip min-width="140" />
              <el-table-column label="操作" align="center" width="110">
                <div slot-scope="{row}" class="flexcc">
                  <el-button type="text" style="padding:0px 5px" size="mini" @click="handlAddorEdit(row)">编辑</el-button>
                  <el-button type="text" style="padding:0px 5px" size="mini" @click="handlDelete(row.qualificationTypeId)">删除</el-button>
                </div>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
      </div>
    </div>
    <!-- 新增 -->
    <el-dialog :title="dialogData.title" :visible.sync="dialogData.status" v-if="dialogData.status" :close-on-click-modal="false" width="400px" @close="closeModel">
      <el-form ref="dialogData" :model="dialogData" style="width: 100%" :rules="rules">
        <el-form-item label="资质类型" label-width="100px" prop="name">
          <el-input v-model="dialogData.name" size="small" maxlength="50" show-word-limit></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer flexcc">
        <el-button @click="closeModel">取 消</el-button>
        <el-button class="bgc-bv" type="primary" @click="doOk">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import List from "@/mixins/List";
export default {
  name: "qualificationsType",
  components: {
    Empty,
  },
  mixins: [List],
  data () {
    return {
      name: "", //资质类型名称
      dialogData: {
        status: false,
        title: '资质类型',
        name: '',
        qualificationTypeId: '',
      },
      rules: {
        name: [
          { required: true, message: "请输入资质类型", trigger: "blur" },
        ],
      },
    };
  },
  created () {
  },
  computed: {},
  methods: {
    /* 获取列表数据 */
    getData () {
      const params = {
      };
      if (this.name) {
        params.name = this.name;
      }
      this.doFetch(
        {
          url: "/biz/qualificationType/pageList",
          params,
        }
      );
    },
    closeModel () {
      this.dialogData = this.$options.data().dialogData
    },
    /* 新增 */
    handlAddorEdit (row) {
      if (row) {
        Object.keys(this.dialogData).forEach(i => {
          if (row[i]) this.dialogData[i] = row[i]
        })
      }
      this.dialogData.status = true
    },
    /* 删除 */
    handlDelete (qualificationTypeId) {
      this.doDel(
        {
          url: "/biz/qualificationType/delete",
          msg: "你确认删除该资质类型吗？",
          ps: {
            type: "post",
            data: { qualificationTypeId },
          },
        }
      );
    },
    doOk () {
      this.$refs["dialogData"].validate((valid) => {
        if (valid) {
          const { qualificationTypeId } = this.dialogData
          let url
          if (qualificationTypeId) {
            url = '/biz/qualificationType/update'
          } else {
            url = '/biz/qualificationType/insert'
          }
          this.$post(url, this.dialogData).then((ret) => {
            if (ret.status == "0") {
              this.$message({
                message: ret.message,
                type: "success",
              });
              this.closeModel();
              this.getData(-1);
            }
          });
        }
      });
    },
  },
};
</script>
<style lang="less"></style>
